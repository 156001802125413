import { Game } from "@/models"
import { PropType } from "vue";

export default {
    name: 'contentTemplate',
    props: {
        game: {} as PropType<Game>,
        keyPrefix: String,
        keyUnique: Number,
    },
    setup(props: any) {
        const idModal = props.keyPrefix + props.keyUnique;
        const pictureUrl = require("../assets/img/gamejams/" + props.game.pictureName);
        let largePictureUrl = pictureUrl;

        if (props.game.pictureName !== props.game.largePictureName) {
            largePictureUrl = require("../assets/img/gamejams/" + props.game.largePictureName);
        }

        return { pictureUrl, largePictureUrl, idModal };
    }
}