<template>
    <!-- Portfolio Item -->
    <div class="col-md-6 col-lg-4 mb-5">
        <div class="portfolio-item mx-auto" data-bs-toggle="modal" :data-bs-target="'#' + idModal">
            <div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                <div class="portfolio-item-caption-content text-center text-white"><i class="fas fa-plus fa-3x"></i>
                </div>
            </div>
            <div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                <div class="portfolio-item-caption-content text-center text-white"><i class="fas fa-plus fa-3x"></i>
                </div>
            </div>
            <img class="img-fluid" :src="pictureUrl" :alt="game.title" />
        </div>
    </div>
    <!-- Portfolio Modal 1-->
    <div class="portfolio-modal modal fade" :id="idModal" tabindex="-1" :aria-labelledby="idModal" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
                        aria-label="Close"></button></div>
                <div class="modal-body text-center pb-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <!-- Portfolio Modal - Title-->
                                <h2 class="portfolio-modal-title text-secondary text-uppercase mb-0">{{ game.title }}
                                </h2>
                                <!-- Icon Divider-->
                                <div class="divider-custom">
                                    <div class="divider-custom-line"></div>
                                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                                    <div class="divider-custom-line"></div>
                                </div>
                                <!-- Portfolio Modal - Image-->
                                <img class="img-fluid rounded mb-1" :src="largePictureUrl" :alt="game.title" />
                                <!-- Portfolio Modal - Text-->
                                <p class="mb-0"><span style="font-weight: bold;">Event:</span> {{ game.event }}</p>
                                <p class="mb-0"><span style="font-weight: bold;">Date:</span> {{ game.date }}</p>
                                <p class="mb-0"><span style="font-weight: bold;">Duration:</span> {{ game.duration }}
                                </p>
                                <p class="mb-0"><span style="font-weight: bold;">Theme:</span> {{ game.theme }}</p>
                                <p v-if="game.object" class="mb-0"><span style="font-weight: bold;">Special Object:</span> {{ game.object }}</p>
                                <p><span style="font-weight: bold;">Team size:</span> {{ game.teamSize }}</p>
                                <p class="mb-4">{{ game.description }}</p>
                                <a class="btn btn-primary" target="_blank" :href="game.pageUrl">Play <i
                                        class="fas fa-arrow-up-right-from-square"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script src="./gameJam.ts"></script>